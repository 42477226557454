import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Select from "react-select";

class AddBottlePopup extends React.Component {

    render() {
        return (
            <div className="overlay" id="addBottlePopup">
                <div className="popup">
                    <p><h5>Add bottles</h5></p>
                    <Form onSubmit={this.props.addBottle} id="addBottleFormId">
                        <Row>
                            <Col>
                                <Form.Control placeholder="Name/description" id="description"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control placeholder="Winery" id="winery"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <form>
                                    <Select isMulti={false} id="country" name="country"
                                            options={this.props.countrySuggestions}
                                            className="basic-multi-select" placeholder="Country"
                                            classNamePrefix="select"
                                            onChange={this.props.onChangeCountryInput}
                                            value={this.props.countrySelectedOption}/>

                                </form>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <form>
                                    <Select isMulti={false} id="region" name="region" placeholder="Region"
                                            options={this.props.regionSuggestions}
                                            className="basic-multi-select" classNamePrefix="select"
                                            onChange={this.props.onChangeRegions}
                                            value={this.props.regionSelectedOption}/>
                                </form>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <form>
                                    <Select isMulti id="grapes" name="grapes" placeholder="Grapes"
                                            options={this.props.grapeSuggestions}
                                            className="basic-multi-select" classNamePrefix="select"
                                            onChange={this.props.onChangeGrapes}
                                            value={this.props.grapeSelectedOptions}/>
                                </form>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control placeholder="Vintage" id="vintage"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Control placeholder="Price" id="price"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            </Col>
                        </Row>
                        <div>

                            <Row>
                                <Col>
                                    <p className="height-three-pixels"/>
                                    <Form.Control placeholder="Amount" id="amount"/>
                                </Col>
                                <Col>
                                    <p className="height-one-pixel"/>
                                    <button type="submit" className="btn btn-primary"
                                            style={{marginTop: 10, backgroundColor: '#630d35', border: "none"}}>
                                        <i class="fa fa-plus"/>
                                        &nbsp;
                                        <i className="fas fa-wine-bottle"/>
                                    </button>
                                    <button type="reset" className="btn btn-cancel"
                                            onClick={this.props.userClicksCancelAddBottle}>Cancel
                                    </button>

                                </Col>
                            </Row>
                        </div>
                    </Form>
                </div>
            </div>
        )
            ;
    }
}

export default AddBottlePopup;
