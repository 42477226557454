import * as React from "react";
import {httpGetWithSecurityToken, httpPostNoBodyWithSecurityToken, httpPostWithSecurityToken} from "../common/rest";
import {determinePagesToDisplay, isBlank, isMobile, underlineHeaderText} from "../common/tools";
import * as constants from "../common/bottleFieldIndexes";
import DrinkBottlePopupWineList from "./DrinkBottlePopupWineList";
import DeleteBottlePopupWineList from "./DeleteBottlePopupWineList";
import AddBottlePopup from "./AddBottlePopup";
import BottleAddedPopup from "./BottleAddedPopup";
import WineListSearchBox from "./WineListSearchBox";
import Bottle from "./Bottle";
import Table from "react-bootstrap/Table";

const bottleIndexColumnNameMap = {
    1: 'description',
    2: 'winery',
    3: 'country',
    4: 'region',
    5: 'grapeVariety',
    6: 'vintage',
    7: 'price',
    8: 'createdDateStr',
    9: 'location'
};

class WinesScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            token: props.token,
            headers: [" ", "Description", "Winery", "Country", "Region", "Grapes", "Vintage", "Price", "Added/Created", "Location"],
            bottleData: [],
            sortAscending: true,
            edit: {
                row: '', //trick to get the rowindex from the dataset
                cell: '',
            },
            searchNeedle: null,
            bottleAdded: null,
            countrySuggestions: [],
            countrySelectedOption: null,
            regionSuggestions: [],
            regionSelectedOption: null,
            grapeSuggestions: [],
            grapeSelectedOptions: [], //multiple choices possible
            bottleCount: null,
            isMobile: false,
            drinkBottleId: '',
            drinkBottleDescription: null,
            deleteBottleId: null,
            deleteBottleDescription: null,
            totalPages: null,
            pagesToDisplay: [],
            currentPage: 0,
            searchActive: null,
        }
        this.sortColumn = this.sortColumn.bind(this);
        this.showEditor = this.showEditor.bind(this);
        this.searchWineList = this.searchWineList.bind(this);
        this.addBottle = this.addBottle.bind(this);
        this.addBottlePopUp = this.addBottlePopUp.bind(this);
        this.userClicksCancelAddBottle = this.userClicksCancelAddBottle.bind(this);
        this.handleRestCallAddBottleForUser = this.handleRestCallAddBottleForUser.bind(this);
        this.deleteBottlePopUp = this.deleteBottlePopUp.bind(this);
        this.userClicksCancelDelete = this.userClicksCancelDelete.bind(this);
        this.deleteBottle = this.deleteBottle.bind(this);
        this.updateBottle = this.updateBottle.bind(this);
        this.loadCountries = this.loadCountries.bind(this);
        this.loadRegionsForCountry = this.loadRegionsForCountry.bind(this);
        this.onChangeCountryInput = this.onChangeCountryInput.bind(this);
        this.loadGrapes = this.loadGrapes.bind(this);
        this.onChangeGrapes = this.onChangeGrapes.bind(this);
        this.onChangeRegions = this.onChangeRegions.bind(this);
        this.checkMobile = this.checkMobile.bind(this);
        this.copyRow = this.copyRow.bind(this);
        this.drinkBottle = this.drinkBottle.bind(this);
        this.drinkBottlePopUp = this.drinkBottlePopUp.bind(this);
        this.userClicksCancelDrink = this.userClicksCancelDrink.bind(this);
        this.handleDeleteBottleRestCall = this.handleDeleteBottleRestCall.bind(this);
        this.loadBottles = this.loadBottles.bind(this);
        this.loadBottlesRemoveFocus = this.loadBottlesRemoveFocus.bind(this);
    }

    componentDidMount() {
        underlineHeaderText('winelist');
        this.loadBottles(0, false, null, '', this.state.sortAscending);
        this.loadCountries();
        this.loadGrapes();
        this.checkMobile();

    }

    //######################## LOAD FUNCTIONS #####################################################
    loadBottlesRemoveFocus(currentPage, searchActive, searchNeedle, sortBy, sortAscending) {
        let el = document.querySelector(':focus');
        if (el) el.blur();
        this.loadBottles(currentPage, searchActive, searchNeedle, sortBy, sortAscending);
    }

    loadBottles(page, searchActive, searchNeedle, sortBy, sortAscending) {
        let url;
        if (searchActive) {
            url = '/api/search-stock-bottles-for-user?searchQuery=' + searchNeedle + '&page=' + page + '&sortBy=' + sortBy + '&sortAscending=' + sortAscending;
        } else {
            url = '/api/get-stock-bottles-for-user?page=' + page + '&sortBy=' + sortBy + '&sortAscending=' + sortAscending;
        }

        httpGetWithSecurityToken(url, this.state.token)
            .then(response => {
                let constructedBottleData = [];
                let rawBottleDataCopy = response.data.dataPaged;
                //pour into an array so slice() etc works
                for (let i = 0, l1 = rawBottleDataCopy.length; i < l1; i++) {
                    let bottle = Object.values(rawBottleDataCopy[i]);
                    bottle.splice(1, 1); //Delete the consumedDate field on bottle, not needed on this screen
                    bottle.splice(10, 3); //Last 3 columns are meaningless for the winescreen
                    constructedBottleData.push(bottle);
                }

                let pagesToDisplay = determinePagesToDisplay(response);

                this.setState({
                    bottleData: constructedBottleData,
                    bottleCount: response.data.totalItems,
                    totalPages: response.data.totalPages,
                    pagesToDisplay: pagesToDisplay,
                    currentPage: response.data.currentPage,
                    searchActive: searchActive,
                    searchNeedle: searchActive ? searchNeedle : this.state.searchNeedle,
                    sortBy: sortBy,
                    sortAscending: sortAscending,
                })
            }).catch(error => {
            this.setState({
                token: '',
            })
        });
    }

    loadCountries() {
        httpGetWithSecurityToken(`/api/get-countries`, this.state.token)
            .then(response => {
                let suggestions = response.data.map((content, idx) => {
                    return {value: content.name, label: content.name}; //format to make the Select (country) component to work
                });

                this.setState({
                    countrySuggestions: suggestions,
                })
            }).catch(error => {
            this.setState({
                countrySuggestions: [],
            })
        });
    }

    loadGrapes() {
        httpGetWithSecurityToken(`/api/get-grapes`, this.state.token)
            .then(response => {
                let suggestions = response.data.map((content, idx) => {
                    return {value: content.name, label: content.name}; //format to make the Select (grapes) component to work
                });
                this.setState({
                    grapeSuggestions: suggestions,
                })
            }).catch(error => {
            this.setState({
                grapeSuggestions: [],
            })
        });
    }

    loadRegionsForCountry(country) {
        httpGetWithSecurityToken(`/api/get-regions-by-country?name=${country}`, this.state.token)
            .then(response => {
                let suggestions = response.data.map((content, idx) => {
                    return {value: content.name, label: content.name}; //format to make the Select (regions) component to work
                });
                this.setState({
                    regionSuggestions: suggestions,
                })
            }).catch(error => {
            this.setState({
                regionSuggestions: [],
            })
        });
    }

    //##################### ADD BOTTLE FUNCTIONS ##############################
    addBottle(event) {
        event.preventDefault();
        const target = event.target;

        //convert the format of the array of the selected grapes that the Select uses to just array of name of grapes
        let grapeSelectedFormatted = this.state.grapeSelectedOptions.map((content, idx) => {
            return ' ' + content.value;
        });

        try {
            this.validateBottle(target.description, target.vintage, target.price, target.amount);
            const body = {
                description: target.description.value,
                winery: target.winery.value,
                country: this.state.countrySelectedOption === null ? null : this.state.countrySelectedOption.value, // { value: content.name, label: content.name };
                region: this.state.regionSelectedOption == null ? null : this.state.regionSelectedOption.value, // { value: content.name, label: content.name };
                grapeVariety: grapeSelectedFormatted.toString().trim(),
                vintage: target.vintage.value,
                price: target.price.value
            };
            this.handleRestCallAddBottleForUser(body, target.amount.value);
            this.clearInputFields(target);
            document.getElementById("addBottlePopup").style.display = "none";

            setTimeout(() => {
                this.setState({
                    bottleAdded: false,
                });
            }, 1500);
        } catch (err) {
            console.log(err);
        }
    }

    //TODO add validation for amount on front end
    handleRestCallAddBottleForUser(body, amount) {
        httpPostWithSecurityToken(`/api/add-bottle-for-user?amount=` + amount, body, this.props.token)
            .then(() => {
                this.loadBottles(this.state.currentPage, this.state.searchActive, this.state.searchNeedle, this.state.sortBy, this.state.sortAscending);
                this.setState({
                    bottleAdded: true,
                });
            })
            .catch(error => {
                this.setState({
                    bottleAdded: false,
                })
            });
    }

    clearInputFields(target) {
        target.description.value = '';
        target.winery.value = '';
        target.vintage.value = '';
        target.price.value = '';
        this.setState({
            countrySelectedOption: null,
            regionSelectedOption: null,
            grapeSelectedOptions: []
        });
    }

    validateBottle(description, vintage, price, amount) {
        let errorMsg = '';
        if (isBlank(description.value)) {
            errorMsg = 'Please name the bottle ';
            description.style.backgroundColor = "#F8D7DA";
            description.placeholder = "Description - Please name the bottle";
            description.value = '';
        } else {
            description.style.backgroundColor = "#ffffff";
            description.placeholder = "Description";
        }

        if (vintage.value != null && (isNaN(vintage.value) || vintage.value.toString().includes('.') || vintage.value.toString().includes(','))) {
            errorMsg = errorMsg + 'Please enter valid year (eg 2018) ';
            vintage.style.backgroundColor = "#F8D7DA";
            vintage.placeholder = "Vintage - Please enter valid year (eg 2018)";
            vintage.value = '';
        } else {
            vintage.style.backgroundColor = "#ffffff";
            vintage.placeholder = "Vintage";
        }

        let priceFormatted = price.value.replace(",", ".");
        if (isNaN(priceFormatted)) {
            errorMsg = errorMsg + 'Please enter valid price (eg 8,95)';
            price.style.backgroundColor = "#F8D7DA";
            price.placeholder = "Price - Please enter valid price (eg 8,95)";
            price.value = '';
        } else {
            price.style.backgroundColor = "#ffffff";
            price.placeholder = "Price";
        }

        if (isBlank(amount.value)){
            amount.value = 1; //default value is 1
            amount.style.backgroundColor = "#ffffff";
            amount.placeholder = "Amount";
        } else if (isNaN(amount.value) || amount.value < 1 || amount.value > 100) {
            errorMsg = errorMsg + 'Please enter a valid amount (1 - 100)';
            amount.style.backgroundColor = "#F8D7DA";
            amount.placeholder = "Please enter a valid amount (1 - 100)";
            amount.value = '';
        } else {
            amount.style.backgroundColor = "#ffffff";
            amount.placeholder = "Amount";
        }
        if (errorMsg !== '') {
            throw errorMsg;
        }
    }

    copyRow(row) {
        let id = row.currentTarget.id;
        let rowCopy;
        let bottleDataCopy = this.state.bottleData.slice();

        for (let i = 0, l1 = bottleDataCopy.length; i < l1; i++) {
            let row = bottleDataCopy[i];
            if (row[0].toString() === id.toString()) {
                rowCopy = bottleDataCopy[i].slice();
                break;
            }
        }
        const body = {
            description: rowCopy[constants.DESCRIPTION_HEADER_INDEX],
            winery: rowCopy[constants.WINERY_HEADER_INDEX],
            country: rowCopy[constants.COUNTRY_HEADER_INDEX],
            region: rowCopy[constants.REGION_HEADER_INDEX],
            grapeVariety: rowCopy[constants.GRAPE_HEADER_INDEX],
            vintage: rowCopy[constants.VINTAGE_HEADER_INDEX],
            price: rowCopy[constants.PRICE_HEADER_INDEX]
        };
        this.handleRestCallAddBottleForUser(body, 1);
        setTimeout(() => {
            this.setState({
                bottleAdded: false,
            });
        }, 1500);
    }

    //#################### DELETE BOTTLE FUNCTIONS ################################
    //Display the delete bottle popup
    deleteBottlePopUp(row) {
        let idAndValue = row.currentTarget.id.split('-');
        this.setState({
            deleteBottleId: idAndValue[0],
            deleteBottleDescription: idAndValue[1],
        });
        document.getElementById("deleteBottlePopup").style.display = "block";
    }

    //User clicks cancel when deleting a bottle
    userClicksCancelDelete() {
        document.getElementById("deleteBottlePopup").style.display = "none";
        this.setState({
            deleteBottleId: null,
            deleteBottleDescription: null,
        });
    }

    //User confirms to delete a bottle
    deleteBottle(event) {
        event.preventDefault();
        document.getElementById("deleteBottlePopup").style.display = "none";
        this.handleDeleteBottleRestCall(this.state.deleteBottleId);
        this.setState({
            deleteBottleId: null,
            deleteBottleDescription: null,
        });
    }

    handleDeleteBottleRestCall(id) {
        httpPostNoBodyWithSecurityToken(`/api/delete-bottle?id=${id}`, this.props.token)
            .then(() => {
                this.loadBottles(this.state.currentPage, this.state.searchActive, this.state.searchNeedle, this.state.sortBy, this.state.sortAscending);
            })
    }

    //################## DRINK BOTTLE FUNCTIONS #########################################
    //User clicks cancel when drinking a bottle
    userClicksCancelDrink() {
        document.getElementById("drinkBottlePopup").style.display = "none";
        this.setState({
            drinkBottleId: null,
            drinkBottleDescription: null,
        });
    }

    drinkBottlePopUp(row) {
        let idAndValue = row.currentTarget.id.split('-');
        this.setState({
            drinkBottleId: idAndValue[0],
            drinkBottleDescription: idAndValue[1]
        });
        document.getElementById("drinkBottlePopup").style.display = "block";
    }

    drinkBottle(event) {
        event.preventDefault();
        document.getElementById("drinkBottlePopup").style.display = "none";

        const target = event.target;

        let bottleDto = {
            id: this.state.drinkBottleId,
            scoreOnTen: target.scoreOnTenId.value,
            tastingNotes: target.tastingNotesId.value
        }
        this.handleRestCallDrinkBottle(bottleDto)

        this.setState({
            drinkBottleId: null,
            drinkBottleDescription: null
        });
    }

    handleRestCallDrinkBottle(bottleDto) {
        httpPostWithSecurityToken(`/api/drink-bottle`, bottleDto, this.props.token)
            .then(() => {
                this.loadBottles(this.state.currentPage, this.state.searchActive, this.state.searchNeedle, this.state.sortBy, this.state.sortAscending);
            })
    }

    //################# ADD BOTTLE FUNCTIONS ###########################################
    addBottlePopUp(event) {
        event.preventDefault();
        document.getElementById("addBottlePopup").style.display = "block";
    }

    userClicksCancelAddBottle() {
        document.getElementById("addBottlePopup").style.display = "none";
    }

    //################# VARIOUS FUNCTIONS ##############################################
    onChangeCountryInput(selectedOption) {
        if (selectedOption != null) {
            this.setState({
                countrySelectedOption: selectedOption,
            })

            this.loadRegionsForCountry(selectedOption.value);
        } else {
            this.setState({
                countrySelectedOption: null
            })
        }
    }

    onChangeGrapes(selectedOptions) {
        if (selectedOptions != null) {
            let selected = selectedOptions.map((content, idx) => {
                return {value: content.value, label: content.value};
            });
            this.setState({
                grapeSelectedOptions: selected,
            })
        } else {
            this.setState({
                grapeSelectedOptions: []
            })
        }
    }

    onChangeRegions(selectedOption) {
        if (selectedOption != null) {
            this.setState({
                regionSelectedOption: selectedOption,
            })
        } else {
            this.setState({
                regionSelectedOption: null
            })
        }
    }

    sortColumn(event) {
        let column = event.target.cellIndex;
        let sortAscending = !this.state.sortAscending; //Clicking sort again changes the order
        let sortBy = bottleIndexColumnNameMap[column];
        this.loadBottles(0, this.state.searchActive, this.state.searchNeedle, sortBy, sortAscending);
    }

    searchWineList(event) {
        if (event.target.value.length > 2) {
            this.loadBottles(0, true, event.target.value, '', this.state.sortAscending);
        } else if (event.target.value.length === 0) {
            this.loadBottles(0, false, null, '', this.state.sortAscending);
        }
    }


    showEditor(event) {
        if (event.target.cellIndex !== 9) { //In wine rack column
            this.setState({
                edit: {
                    row: parseInt(event.target.dataset.row, 10), //trick to get the rowindex from the dataset
                    cell: event.target.cellIndex,
                }
            });
        }
    }

    //This method is here to update the bottleData with the edited value
    updateBottle(event) {
        let row = this.state.edit.row;
        let cell = this.state.edit.cell;
        let bottleDataCopy = this.state.bottleData.slice();

        if (this.state.searchNeedle !== null) {
            //trick to get the input field value inside the form
            bottleDataCopy[row][cell] = event.target.querySelector('input').value;

            this.setState({
                bottleData: bottleDataCopy,
                edit: null
            });
        } else {
            //trick to get the input field value inside the form
            bottleDataCopy[row][cell] = event.target.querySelector('input').value;

            this.setState({
                bottleData: bottleDataCopy,
                edit: null
            });
        }
    }

    checkMobile() {
        let check = isMobile();
        this.setState({
            isMobile: check
        })
    }

    render() {
        return (
            <div className="shadow pt-3 pb-1 pl-3 pr-3 mb-3 bg-white rounded">
                { //################### POP UPS ######################
                    this.state.bottleAdded && <BottleAddedPopup/>
                }
                <DrinkBottlePopupWineList key={'DrinkBottlePopupWineListKey'}
                                          drinkBottleDescription={this.state.drinkBottleDescription}
                                          drinkBottle={this.drinkBottle}
                                          userClicksCancelDrink={this.userClicksCancelDrink}/>
                <DeleteBottlePopupWineList key={'DeleteBottlePopupWineListKey'}
                                           deleteBottleDescription={this.state.deleteBottleDescription}
                                           deleteBottle={this.deleteBottle}
                                           userClicksCancelDelete={this.userClicksCancelDelete}/>
                <AddBottlePopup key={'AddBottlePopupWineListKey'}
                                addBottle={this.addBottle} onChangeCountryInput={this.onChangeCountryInput}
                                onChangeRegions={this.onChangeRegions} onChangeGrapes={this.onChangeGrapes}
                                countrySuggestions={this.state.countrySuggestions}
                                countrySelectedOption={this.state.countrySelectedOption}
                                regionSuggestions={this.state.regionSuggestions}
                                regionSelectedOption={this.state.regionSelectedOption}
                                grapeSuggestions={this.state.grapeSuggestions}
                                grapeSelectedOptions={this.state.grapeSelectedOptions}
                                userClicksCancelAddBottle={this.userClicksCancelAddBottle}/>

                {//#################### WINE LIST SCREEN ##############
                }
                <div align="center">
                    <p className="height-fifty-pixels">&nbsp;</p>
                </div>
                <p className="height-three-pixels"/>
                <div className="container">
                    <div className="element1">
                        <p className="height-three-pixels"/>
                        <a href="#" id='addBottleButtonId' onClick={this.addBottlePopUp} className="button-link">
                            Add bottle
                        </a>
                    </div>
                    <div className="element2">
                        <WineListSearchBox search={this.searchWineList}/>
                    </div>
                </div>

                <p className="height-three-pixels"/>
                <div style={{overflowX: "auto"}}>
                    <Table>
                        <thead onClick={this.sortColumn}>
                        <tr>
                            {
                                this.state.headers.map((title, idx) => {
                                    if (this.state.sortBy === bottleIndexColumnNameMap[idx]) {
                                        title += this.state.sortAscending ? '\u2191' : '\u2193';
                                    }
                                    return <th key={idx} className="align-text-top">{title} &nbsp;</th>;
                                })
                            }
                        </tr>
                        </thead>
                        <tbody onClick={this.showEditor} onSubmit={this.updateBottle}>
                        {
                            this.state.bottleData.map((row, rowidx) => {
                                    return (
                                        //We take the bottle ID as key here, key is also needed for the sorting to work
                                        <Bottle key={row[0]} rowIndex={rowidx} content={row} edit={this.state.edit}
                                                token={this.state.token} deleteBottlePopUp={this.deleteBottlePopUp}
                                                copyRow={this.copyRow} drinkBottlePopUp={this.drinkBottlePopUp}/>
                                    );
                                }
                            )
                        }
                        </tbody>
                    </Table>
                </div>
                { //#################### PAGINATION WINE LIST SCREEN ##############
                }
                <div>
                    <Table responsive="sm">
                        <ul className="pagination">
                            {
                                this.state.currentPage !== 0 && this.state.totalPages > 1 &&
                                <li className="page-item">
                                    <a className="page-link text-color-bordeaux" href="#"
                                       id='Previous'
                                       onClick={event => this.loadBottlesRemoveFocus(this.state.currentPage - 1,
                                           this.state.searchActive, this.state.searchNeedle, this.state.sortBy, this.state.sortAscending)}>&lt;

                                    </a>
                                </li>
                            }
                            {
                                this.state.pagesToDisplay.map((page, rowidx) => {
                                        if (page === this.state.currentPage) {
                                            return (
                                                <li className="page-item">
                                                    <a className="page-link bg-color-bordeaux text-color-white"
                                                       href="#">{page + 1}</a>
                                                </li>);
                                        } else if (page === -1) {
                                            return (
                                                <li className="page-item">
                                                    <a className="page-link text-color-bordeaux">...</a>
                                                </li>);
                                        } else {
                                            return (
                                                <li className="page-item">
                                                    <a className="page-link text-color-bordeaux" href="#"
                                                       id={rowidx}
                                                       onClick={event => this.loadBottlesRemoveFocus(page, this.state.searchActive,
                                                           this.state.searchNeedle, this.state.sortBy, this.state.sortAscending)}>{page + 1}</a>
                                                </li>
                                            );
                                        }
                                    }
                                )
                            }
                            {this.state.currentPage !== this.state.totalPages - 1 && this.state.totalPages > 1 &&
                                <li className="page-item">
                                    <a className="page-link text-color-bordeaux" href="#"
                                       id='Next'
                                       onClick={event => this.loadBottlesRemoveFocus(this.state.currentPage + 1,
                                           this.state.searchActive, this.state.searchNeedle, this.state.sortBy, this.state.sortAscending)}>&gt;</a>
                                </li>
                            }
                        </ul>
                    </Table>
                </div>
                <div><p>
                    {this.state.searchActive &&
                        <b>Matching search: {this.state.bottleCount} bottles</b>
                    }
                    {!this.state.searchActive &&
                        <b>Total: {this.state.bottleCount} bottles</b>
                    }
                </p></div>
            </div>
        );
    }
}

export default WinesScreen;